<template>
  <AtomInput
    type="select"
    name="Способ связи"
    :options="contactTypes"
    v-model="typeModel"
    :error="errorType"
    :can-deselect="false"
  />
  <AtomInput
    v-if="['phone', 'whatsapp'].includes(typeModel)"
    ref="input"
    name="Номер телефона"
    inputmask="+9 999 999 99 99"
    v-model="model"
    :error="error"
  />
  <AtomInput
    v-else
    ref="input"
    name="Ссылка"
    v-model="model"
    :error="error"
  />
</template>

<script setup>
import AtomInput from '@/components/common/AtomInput'
import contactTypes from '@/data/contactTypes'

const props = defineProps(['type', 'modelValue', 'errorType', 'error'])
const emit = defineEmits(['update:type', 'update:modelValue'])

const input = ref()
const typeModel = computed({
  get: () => props.type,
  set: value => emit('update:type', value)
})
const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

watch(typeModel, () => nextTick(() => (model.value = '')))
// onMounted(() => !typeModel.value && (typeModel.value = 'phone'))
</script>

<style scoped lang="scss">

</style>
